import React from 'react'
import {graphql} from 'gatsby';
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Nav from '../components/nav'
import Project from '../components/project'
import Footer from '../components/footer'

export const query = graphql`
  query projectTemplateQuery($slug: String!){
    project: sanityPortfolioMainItem(slug: {current: {eq: $slug}}) {
      name
      excerpt
      tags
      type
      id
      slug {
          _key
          _type
          current
      }
      _rawDescription
      _rawProjectGallery
      bannerImage {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
      }
      collaborators {
        collaborators {
          name
          portfolioLink
        }
      }
      role
      links {
        linkTitle
        url
      }
      tools
      _rawProcessSections
    }
  }
`

const projectPage = props => {
  const { data } = props;
  console.log(data);

  return (
    <Layout>
      <SEO title={data.project.name + " | The Varun Khatri"}/>
      <Nav scrollPoint="#projectHero"/>
      <Project {...props}/>
      <Footer/>
    </Layout>
  )
}

export default projectPage
