import React, {useState, useEffect} from 'react'
import {Link} from 'gatsby'
import Img from "gatsby-image"
import Arrow from '../assets/arrow.svg'
import PortableText from './portableText'
import { SRLWrapper } from 'simple-react-lightbox'
import scrollTo from 'gatsby-plugin-smoothscroll'
import clientConfig from '../../client-config'
import {getFluidGatsbyImage} from 'gatsby-source-sanity'

import '../styles/project.scss'

const ProcessSection = ({sectionTitle, blockContent, hidden}) => {
    let sectionId = sectionTitle.toLowerCase().replace(/\s/g, '-');

    if(hidden) {
        return null
    }

    return (
        <div className="processSection" id={sectionId}>
            <h3 className="sectionTitle">{sectionTitle}</h3>
            <PortableText blocks={blockContent}/>
        </div>
    )
}

const GalleryItem = ({data}) => {
    console.log(data);

    if(data._type == "visionVideoEmbed"){
        return(
            <div className="galleryItemContainer">
                <div className="galleryItemVideo">
                    <iframe src={data.embedLink} allow="autoplay; fullscreen; picture-in-picture"  allowfullscreen></iframe>
                </div>
                <div className="galleryItemCaption">
                    <h3>Vision Video</h3>
                </div>
            </div>
        )

    } else if(data._type == "prototypeEmbed") {
        return(
            <div className="galleryItemContainer">
                <div className="galleryItemPrototype">
                    <iframe width="100%" height="500px" src={data.embedLink} allowfullscreen></iframe>
                </div>
                <div className="galleryItemCaption">
                    <h3>Prototype</h3>
                </div>
            </div>
        )
    } else if(data._type == "projectGalleryImage") {
        const fluidImage = getFluidGatsbyImage(
            data.image.asset._ref,
            {maxWidth: 1200},
            clientConfig.sanity
        )

        return(
            <div className="galleryItemContainer">
                <div className="galleryItemImage">
                    <Img fluid={fluidImage}/>
                </div>
                <div className="galleryItemCaption">
                    <PortableText blocks={data.imageCaption}/>
                </div>
            </div>
        )
    } else {
        return null;
    }
}

const ProcessNav = ({sections}) => {
    const [sticky, setSticky] = useState(false);
    const [highlightedItem, setHighlightedItem] = useState(0);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return function cleanup() {
        window.removeEventListener("scroll", handleScroll)
        };
    });
    
    const handleScroll = () => {
        var mainBounding = document.querySelector('#processContainer').getBoundingClientRect();

        if (mainBounding.top < 80) {
            if(!sticky){
            setSticky(true);
            }
        } else {
            if(sticky){
            setSticky(false);
            }
        }

        for(let i = sections.length; i > 0 ; i--) {
            checkBounding(sections[i-1], i-1);
        }
    }
    
    const checkBounding =  (item, index) => {
        let queryItem = "#" + item.sectionTitle.toLowerCase().replace(/\s/g, '-');

        let boundingInfo = document.querySelector(queryItem).getBoundingClientRect();
        if (boundingInfo.top < 50 && boundingInfo.bottom > 0) {
        if (highlightedItem !== index){
            setHighlightedItem(index);
        }
        }
    }
    
    return (
        <div className={sticky ? "processNav fixed" : "processNav"}>
            <h3>Process</h3>
            <ul>
                {
                    sections.map((section, index) => {
                        let itemID = "#" + section.sectionTitle.toLowerCase().replace(/\s/g, '-');

                        return(
                            <li onClick={() => scrollTo(itemID)} key={index} className={(index === highlightedItem) ?"selected" : null}>{section.sectionTitle}</li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

const MobileProcessNav = ({sections}) => {
    const [hidden, setHidden] = useState(true);
    const [highlightedItem, setHighlightedItem] = useState(0);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return function cleanup() {
            window.removeEventListener("scroll", handleScroll)
        };
    });
    
    const handleScroll = () => {
        var mainBounding = document.querySelector('#processContainer').getBoundingClientRect();
        
        if (mainBounding.top < 200 && mainBounding.bottom > 500) {
            if(!hidden){
            setHidden(true);
            }
        } else {
            if(hidden){
            setHidden(false);
            }
        }

        for(let i = sections.length; i > 0 ; i--) {
            checkBounding(sections[i-1], i-1);
        }
    }
    
    
    const checkBounding =  (item, index) => {
        let queryItem = "#" + item.sectionTitle.toLowerCase().replace(/\s/g, '-');

        let boundingInfo = document.querySelector(queryItem).getBoundingClientRect();
        if (boundingInfo.top < 50 && boundingInfo.bottom > 0) {
        if (highlightedItem !== index){
            setHighlightedItem(index);
        }
        }
    }
    
    return (
        <div className={hidden ? "mobileProcessNav": "mobileProcessNav hidden"}>
            <div className="container">
                <ul>
                    {
                        sections.map((section, index) => {
                            let itemID = "#" + section.sectionTitle.toLowerCase().replace(/\s/g, '-');

                            return(
                                <li onClick={() => scrollTo(itemID)} key={index} className={(index === highlightedItem) ?"selected" : null}>{section.sectionTitle}</li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}

const OverviewSection = ({sectionTitle, content}) => {
    if (content.length === 0) {
      return null;
    }
  
    return (
        <>
            <h3>{sectionTitle}</h3>
            <ul>
              {content}
            </ul>
        </>
    )
}

const ProjectGallery = ({content}) => {
    if (content.length === 0) {
      return null;
    }
  
    return (
        <div className="projectGallery">
            <h3>Project Gallery</h3>
            { content }
        </div>
    )
}

const Project = props => {
  const { data } = props;
  const projectData = data.project;

  const projectRole = projectData.role.map((role, index)=>(
    <li key={index}>{role}</li>
  ))

  const projectCollaborators = projectData.collaborators.map((collaborator, index)=>{
    if(collaborator.collaborators.portfolioLink){
        return(<a href={collaborator.collaborators.portfolioLink} target="_blank"><li>{collaborator.collaborators.name}<Arrow/></li></a>)
    } else {
        return(<li>{collaborator.collaborators.name}</li>)
    }
  })

  const projectTools = projectData.tools.map((tool, index)=>(
    <li key={index}>{tool}</li>
  ))

  const projectLinks = projectData.links.map((link, index)=>(
    <a href={link.url} target="_blank"><li>{link.linkTitle}<Arrow/></li></a>
  ))

  const projectGallery = projectData._rawProjectGallery.map((itemData, index)=>(
    <GalleryItem data={itemData} key={index}/>
  ))

  console.log(projectData)  

  return (
    <SRLWrapper>
        <div id="projectHero">
            <div className="container">
                <Link to="/"><div className="back"><Arrow/> Back</div></Link>
                <div className="projectHeader">
                    <h1>{projectData.name}</h1>
                    <h2>{projectData.type}</h2>
                    <p>{projectData.excerpt}</p>
                </div> 
                <Img fluid={projectData.bannerImage.asset.fluid}/>
            </div>
        </div>
        <main id="projectMain">
            <div className="container">
                <div className="projectOverview">
                    <div className="projectOverviewDescription">
                        <h3>Description</h3>
                        <PortableText blocks={projectData._rawDescription}/>
                    </div>
                    <div>
                        <OverviewSection sectionTitle="Role" content={projectRole}/>
                        <OverviewSection sectionTitle="Tools" content={projectTools}/>
                    </div>
                    <div>
                        <OverviewSection sectionTitle="Collaborators" content={projectCollaborators}/>
                        <OverviewSection sectionTitle="Links" content={projectLinks}/>
                    </div>
                </div>
                <ProjectGallery content={projectGallery}/>
                <div className="processContainer" id="processContainer">
                    <ProcessNav sections={projectData._rawProcessSections.filter(section => !section.hidden)}/>
                    <div className="projectProcess">
                        {
                           projectData._rawProcessSections.map((section, index)=>(
                                <ProcessSection key={section.key} sectionTitle={section.sectionTitle} blockContent={section.details} hidden={section.hidden}/>
                           )) 
                        }
                    </div>
                </div>
                <MobileProcessNav sections={projectData._rawProcessSections.filter(section => !section.hidden)}/>
            </div>
        </main>
    </SRLWrapper>
  )
}

export default Project
